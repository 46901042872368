import { useState } from 'react';
import { CodeBlock } from '@rainbow-modules/content';
import { Modal, Button } from 'react-rainbow-components';
import Option from './option';
import { Header, HeaderTitle, ModalTitle, StyledButton, ModalDescription } from './styled';
import { useNavigate } from 'react-router-dom';

const code = `<div data-tf-popover="111111111" data-tf-button-color="#0445AF" data-tf-iframe-props="title=messages" data-tf-medium="snippet" style="all:unset;"></div><script src="//embed.reTalkable.com/next/embed.js"></script>
`;

const LeftBar = () => {
    const [isOpen, setOpenStatus] = useState(false);
    const navigate = useNavigate();
    const isIdentity = window.location.pathname.includes('identity');

    return (
        <div className="rainbow-m-right_small">
            <Header>
                <HeaderTitle>Embed in a web page</HeaderTitle>
            </Header>
            <Option name="Preview" onClick={() => navigate('preview')} isSelected={!isIdentity} />
            <Option name="Identity" onClick={() => navigate('identity')} isSelected={isIdentity} />
            <StyledButton
                label="Get the code"
                borderRadius="semi-rounded"
                variant="outline-brand"
                className="rainbow-m-top_small"
                onClick={() => setOpenStatus(true)}
            />
            <Modal
                title={<ModalTitle>Grab your embed code</ModalTitle>}
                isOpen={isOpen}
                onRequestClose={() => setOpenStatus(false)}
                borderRadius="semi-rounded"
                footer={
                    <div className="rainbow-flex rainbow-justify_end">
                        <Button
                            form="redux-form-id"
                            className="rainbow-m-right_large"
                            label="Close"
                            variant="neutral"
                            onClick={() => setOpenStatus(false)}
                            borderRadius="semi-rounded"
                        />
                        <Button label="Copy Code" variant="brand" borderRadius="semi-rounded" />
                    </div>
                }
            >
                <ModalDescription>
                    Place the code in the `body` of your page&apos;s HTML. The exact position
                    doesn&apos;t matter—it won&apos;t affect how your widget is displayed.
                </ModalDescription>
                <CodeBlock
                    className="rainbow-m-vertical_large rainbow-m-horizontal_small"
                    label="JavaScript"
                    value={code}
                    theme="dark"
                />
            </Modal>
        </div>
    );
};

export default LeftBar;
