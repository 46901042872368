/* eslint-disable react/no-unescaped-entities */
import { CSSProperties } from 'react';
import {
    Container,
    Title,
    Description,
    Icon,
    Bold,
    EmptyMessageContainer,
    ContactsIcon,
} from './styled';
import { StateResultsProvided, connectStateResults } from 'react-instantsearch-core';
import { RenderIf } from 'react-rainbow-components';
import EmptyMessage from '../EmptyMessage';

interface SearchEmptyMessageProps extends StateResultsProvided {
    style?: CSSProperties;
    className?: string;
}

const SearchEmptyMessage = (props: SearchEmptyMessageProps) => {
    const { className, style } = props;

    if (!props.searching && props.searchResults && props.searchResults.nbHits === 0) {
        const { query } = props.searchState;
        return (
            <Container style={style} className={className}>
                <RenderIf isTrue={query && query.length > 0}>
                    <EmptyMessageContainer className="rainbow-m-top_x-large rainbow-p-top_x-large rainbow-m-horizontal_large">
                        <Icon />
                        <Title>
                            No Results for <Bold>"{props.searchState.query}"</Bold>
                        </Title>
                        <Description>Check the spelling or try a new search.</Description>
                    </EmptyMessageContainer>
                </RenderIf>
                <RenderIf isTrue={query === undefined || query.length === 0}>
                    <EmptyMessage
                        className="rainbow-m-top_x-large rainbow-p-top_x-large rainbow-m-horizontal_large"
                        icon={<ContactsIcon />}
                        title="You don’t have contacts"
                        description="To start messaging, you must first create or import your contacts."
                    />
                </RenderIf>
            </Container>
        );
    }
    return null;
};
export default connectStateResults(SearchEmptyMessage);
