import { Column, Table } from 'react-rainbow-components';
import ActionsColumn from './columns/actions';
import ApplicationColumn from './columns/application';
import { StyledDateTimeColumn } from './styled';
import { DateTimeColumnProps, GroupCountColumnProps } from './types';
import GroupCountColumn from './columns/groupCount';
import { App } from '@transactional-message-tool/shared';

const DateTimeColumn = (props: DateTimeColumnProps) => {
    const { value } = props;
    return <StyledDateTimeColumn value={value} />;
};

const GroupCountColumnWithMargin = (props: GroupCountColumnProps) => {
    const { row } = props;
    return <GroupCountColumn className="rainbow-m-left_small" row={row} />;
};

interface Props {
    apps: App[];
    isLoading: boolean;
}

const AppsTable = (props: Props) => {
    const { apps, isLoading } = props;
    return (
        <>
            <Table
                isLoading={isLoading}
                data={apps}
                keyField="id"
                variant="listview"
                className="rainbow-p-horizontal_x-large"
            >
                <Column header="Application" component={ApplicationColumn} headerAlignment="left" />
                <Column
                    header="Created At"
                    field="createdAt"
                    component={DateTimeColumn}
                    headerAlignment="left"
                />
                <Column
                    header="Updated At"
                    field="updatedAt"
                    component={DateTimeColumn}
                    headerAlignment="left"
                />
                <Column
                    header="Groups"
                    component={GroupCountColumnWithMargin}
                    headerAlignment="left"
                />
                <Column component={ActionsColumn} width={150} />
            </Table>
        </>
    );
};

export default AppsTable;
