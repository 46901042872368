import { login } from 'data/services/auth';
import {
    Container,
    LoginWrapper,
    LoginLogo,
    WelcomeMessage,
    FormContainer,
    StyledGoogleIcon,
    StyledButton,
    TermsAndConditions,
    Footer,
    TermsLink,
} from './styled';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const navigate = useNavigate();
    return (
        <Container>
            <LoginWrapper>
                <LoginLogo />
                <FormContainer>
                    <WelcomeMessage>Welcome to reTalkable</WelcomeMessage>
                    <StyledButton borderRadius="semi-rounded" onClick={login}>
                        <StyledGoogleIcon />
                        Continue with Google
                    </StyledButton>
                    <TermsAndConditions>
                        <p>
                            By signing up, you have read and agree to our <br />
                            <TermsLink onClick={() => navigate('/terms-of-service')}>
                                {' '}
                                terms of service
                            </TermsLink>{' '}
                            and the{' '}
                            <TermsLink onClick={() => navigate('/privacy-policy')}>
                                {' '}
                                privacy policy
                            </TermsLink>
                        </p>
                    </TermsAndConditions>
                </FormContainer>
                <Footer>Company Name 2022</Footer>
            </LoginWrapper>
        </Container>
    );
};

export default Login;
