import Contact from './contact';
import { Hit, BasicDoc } from 'react-instantsearch-core';
import { StyledHighlight } from '../styled';
import formatter from 'data/services/date/formatter';
import { PhoneNumberParts } from '@transactional-message-tool/shared';

interface Props {
    style?: React.CSSProperties;
    className?: string;
    hit: Hit<BasicDoc>;
}

interface AlgoliaFirestoreTimestamp {
    _seconds: number;
    _nanoseconds: number;
}

const AlgoliaContact = (props: Props) => {
    const createdAt = formatter.format(
        (props.hit.createdAt as unknown as AlgoliaFirestoreTimestamp)._seconds * 1000,
    );
    return (
        <Contact
            firstName={<StyledHighlight attribute="firstName" hit={props.hit} />}
            lastName={<StyledHighlight attribute="lastName" hit={props.hit} />}
            phoneNumber={<StyledHighlight attribute="phoneNumber" hit={props.hit} />}
            createdAt={createdAt}
            phoneNumberParts={props.hit.phoneNumberParts as unknown as PhoneNumberParts}
        />
    );
};

export default AlgoliaContact;
