/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FieldContainer, StyledSaveButton, StyledInput } from '../styled';
import { Field, isUrl } from '@rainbow-modules/forms';
import { useField } from 'react-final-form';

interface Props {
    initialAuthUrl: string;
}

const AuthUrlFormFields = (props: Props) => {
    const { initialAuthUrl } = props;
    const value = useField('authUrl', { subscription: { value: true } }).input.value;

    return (
        <FieldContainer>
            <Field
                name="authUrl"
                labelAlignment="left"
                // @ts-ignore
                component={StyledInput}
                label="Authentication URL"
                borderRadius="semi-rounded"
                validate={isUrl()}
                placeholder="Enter the URL for your authentication endpoint"
            />
            <StyledSaveButton
                label="Save"
                variant="outline-brand"
                borderRadius="semi-rounded"
                type="submit"
                form="authUrlForm"
                disabled={initialAuthUrl === value}
            />
        </FieldContainer>
    );
};

export default AuthUrlFormFields;
