import styled from 'styled-components';
import { Plus, Trash, Warning } from '@rainbow-modules/icons';
import { KeyImage } from '../../../../components/icons';
import { Button } from 'react-rainbow-components';
import { color } from '../../../../theme';
import { CopyToClipboardButton } from '@rainbow-modules/record';

export const Container = styled.div`
    padding: 32px 8px 32px 8px;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
`;

export const StyledPlusIcon = styled(Plus)`
    margin-right: 8px;
`;

export const StyledButton = styled(Button)`
    flex-shrink: 0;
    margin-top: 8px;
`;
interface Props {
    new?: boolean;
}

export const StyledColumnWrapper = styled.div<Props>`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding-left: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;

    ${(props) =>
        props.new &&
        `
            background-color: #f5f5fa;
        `};
`;

export const ToggleLabel = styled.span`
    font-size: 12px;
    color: ${color('text.header')};
`;

export const NameColumnValue = styled.span<Props>`
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    color: ${color('text.main')};

    ${(props) =>
        props.new &&
        `
            font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
            color: ${color('text.main')};
            line-height: 1.5;
            margin-top: 12px;
        `};
`;

export const StyledColumnNameRow = styled.div`
    display: flex;
    justify-content: space-between;
    padding-right: 12px;
    width: 100%;
`;

export const StyledKeyValueContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin-right: 16px;
`;

export const StyledKeyValueContent = styled.div`
    border: 1px solid ${color('brand.main')};
    border-radius: 10px;
    margin: 8px 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: #fff;
    height: 34px;
`;

export const KeyValue = styled.span`
    font-size: 15px;
    color: ${color('text.main')};
    padding: 0 24px 0 12px;
    border-right: 1px solid ${color('brand.main')};
    flex-grow: 1;
    text-align: left;
    line-height: 32px;
`;

export const StyledCopyToClipboardButton = styled(CopyToClipboardButton)`
    padding: 8px;
    color: ${color('brand.main')};
    background-color: #d9d3e8;
    border-radius: 0 9px 9px 0;
    height: 32px;
    width: 32px;
`;

export const StyledWarningContainer = styled.div`
    color: #a77940;
    display: flex;
    align-items: center;
    line-height: 1.2;
    margin-bottom: 16px;
    margin-right: 12px;
`;

export const StyledWarningIcon = styled(Warning)`
    margin-right: 12px;
    width: 16px;
    height: 16px;
    flex-shrink: 0;
`;

export const StyledWarningText = styled.p`
    font-size: 13px;
    white-space: pre-line;
    text-align: left;
`;

export const FormContainer = styled.div`
    padding: 0 20px 16px 20px;
`;

export const StyledKeyImage = styled(KeyImage)`
    margin-bottom: 20px;
`;

export const TrashIconDelete = styled(Trash)`
    width: 60px;
    height: 60px;
    margin: 0 12px 0 0;
    color: ${color('text.main')};
`;
