/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Input, Option, Picklist, RenderIf } from 'react-rainbow-components';
import { SelectPhoneNumberContainer, StateErrorMessage, StyledButton } from './styled';
import { states } from '../../constants';
import { Field } from '@rainbow-modules/forms';
import isStateOrAreaCodeValidator from 'helpers/isStateOrAreaCodeValidator';

interface SelectPhoneNumberFormProps {
    onSearchAvailableNumbers: () => void;
    selectedState: Record<string, unknown>;
    areaCode: string;
    buttonDisabled: boolean;
    showError: boolean;
    errorMessage: string;
}

const StatesPicklist = (props: Record<string, unknown>) => {
    return <Picklist {...props} />;
};

const SelectStateAndAreaCode = (props: SelectPhoneNumberFormProps) => {
    const {
        onSearchAvailableNumbers,
        selectedState,
        areaCode,
        buttonDisabled,
        showError,
        errorMessage,
    } = props;

    return (
        <>
            <SelectPhoneNumberContainer>
                <Field
                    component={StatesPicklist}
                    id="states"
                    name="states"
                    label="Select State"
                    placeholder="Select state"
                    required
                    borderRadius="semi-rounded"
                    labelAlignment="left"
                    enableSearch
                    className="rainbow-m-bottom_large"
                    value={selectedState}
                    validate={isStateOrAreaCodeValidator}
                >
                    {states.map((state) => (
                        <Option
                            key={state.abbreviation}
                            name={state.name}
                            label={state.name}
                            value={state.abbreviation}
                        />
                    ))}
                </Field>
                <Field
                    //@ts-ignore
                    component={Input}
                    id="areaCode"
                    name="areaCode"
                    label="Area Code"
                    placeholder="Ex: 456"
                    value={areaCode}
                    validate={isStateOrAreaCodeValidator}
                    borderRadius="semi-rounded"
                    labelAlignment="left"
                    required
                    className="rainbow-m-bottom_large"
                />
                <StyledButton
                    label="Search"
                    borderRadius="semi-rounded"
                    onClick={onSearchAvailableNumbers}
                    disabled={buttonDisabled}
                />
            </SelectPhoneNumberContainer>
            <RenderIf isTrue={showError}>
                <StateErrorMessage>{errorMessage}</StateErrorMessage>
            </RenderIf>
        </>
    );
};

export default SelectStateAndAreaCode;
