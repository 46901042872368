import PropTypes from 'prop-types';
import { IconProps } from './types';

const Home = ({ title, className, style }: IconProps) => {
    return (
        <svg
            className={className}
            style={style}
            width={18}
            height={20}
            viewBox="0 0 18 20"
            fill="none"
        >
            <title>{title}</title>
            <path
                d="m1.494 7.222 8-6.222 8 6.222V17a1.778 1.778 0 0 1-1.778 1.778H3.272A1.778 1.778 0 0 1 1.494 17V7.222Z"
                stroke="currentColor"
                strokeOpacity={0.9}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.827 18.778v-8.89h5.334v8.89"
                stroke="currentColor"
                strokeOpacity={0.9}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

Home.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    title: PropTypes.string,
};

Home.defaultProps = {
    className: undefined,
    style: undefined,
    title: 'home',
};

export default Home;
