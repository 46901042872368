import styled from 'styled-components';
import { MagnifyingGlass } from '@rainbow-modules/icons';
import { color } from '../../theme';
import Contacts from 'components/icons/contacts';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Title = styled.h3`
    font-size: 18px;
    color: ${color('text.main')};
`;

export const Description = styled.p`
    font-size: 14px;
    color: ${color('text.header')};
    margin-top: 4px;
`;

export const Icon = styled(MagnifyingGlass)`
    width: 48px;
    height: 48px;
    color: ${color('border.divider')};
    margin-bottom: 12px;
`;

export const Bold = styled.span`
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
`;

export const EmptyMessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const ContactsIcon = styled(Contacts)`
    margin-bottom: 12px;
    height: 48px;
    width: 48px;
    flex-shrink: 0;
`;
