import PropTypes from 'prop-types';
import { IconProps } from './types';

const Users = ({ title, className, style }: IconProps) => (
    <svg width={24} height={24} viewBox="0 0 24 24" className={className} style={style}>
        <title>{title}</title>
        <path
            fill="currentColor"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.81802 15.818C2.66193 14.9741 3.80653 14.5 5 14.5H13C14.1935 14.5 15.3381 14.9741 16.182 15.818C17.0259 16.6619 17.5 17.8065 17.5 19V21C17.5 21.2761 17.2761 21.5 17 21.5C16.7239 21.5 16.5 21.2761 16.5 21V19C16.5 18.0717 16.1313 17.1815 15.4749 16.5251C14.8185 15.8687 13.9283 15.5 13 15.5H5C4.07174 15.5 3.1815 15.8687 2.52513 16.5251C1.86875 17.1815 1.5 18.0717 1.5 19V21C1.5 21.2761 1.27614 21.5 1 21.5C0.723858 21.5 0.5 21.2761 0.5 21V19C0.5 17.8065 0.974106 16.6619 1.81802 15.818Z"
        />
        <path
            fill="currentColor"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9 3.5C7.067 3.5 5.5 5.067 5.5 7C5.5 8.933 7.067 10.5 9 10.5C10.933 10.5 12.5 8.933 12.5 7C12.5 5.067 10.933 3.5 9 3.5ZM4.5 7C4.5 4.51472 6.51472 2.5 9 2.5C11.4853 2.5 13.5 4.51472 13.5 7C13.5 9.48528 11.4853 11.5 9 11.5C6.51472 11.5 4.5 9.48528 4.5 7Z"
        />
        <path
            fill="currentColor"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.5159 15.005C19.5849 14.7376 19.8576 14.5768 20.125 14.6459C21.0904 14.8951 21.9457 15.458 22.5565 16.246C23.1674 17.034 23.4993 18.0026 23.5 18.9996L23.5 19V21C23.5 21.2761 23.2761 21.5 23 21.5C22.7239 21.5 22.5 21.2761 22.5 21L22.5 19.0004C22.5 19.0003 22.5 19.0002 22.5 19.0001C22.5 19.0001 22.5 19 22.5 19C22.4993 18.2246 22.2412 17.4715 21.7662 16.8587C21.2911 16.2458 20.6259 15.808 19.875 15.6141C19.6076 15.5451 19.4468 15.2724 19.5159 15.005Z"
        />
        <path
            fill="currentColor"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.5156 3.00598C15.5841 2.73847 15.8565 2.57713 16.124 2.64563C17.092 2.89347 17.9499 3.45642 18.5626 4.24573C19.1753 5.03504 19.5078 6.00581 19.5078 7.005C19.5078 8.00419 19.1753 8.97497 18.5626 9.76428C17.9499 10.5536 17.092 11.1165 16.124 11.3644C15.8565 11.4329 15.5841 11.2715 15.5156 11.004C15.4471 10.7365 15.6085 10.4641 15.876 10.3956C16.6288 10.2029 17.2961 9.76501 17.7727 9.15111C18.2492 8.5372 18.5078 7.78215 18.5078 7.005C18.5078 6.22785 18.2492 5.47281 17.7727 4.8589C17.2961 4.24499 16.6288 3.80714 15.876 3.61438C15.6085 3.54588 15.4471 3.2735 15.5156 3.00598Z"
        />
    </svg>
);

Users.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    title: PropTypes.string,
};

Users.defaultProps = {
    className: undefined,
    style: undefined,
    title: 'Users',
};

export default Users;
