import { Button } from 'react-rainbow-components';
import { StyledLink, StyledNoMessageContainer, StyledPlusIcon } from '../styled';
import Contacts from 'components/icons/contacts';
// import { useOpenModal } from '@rainbow-modules/hooks';
// import useMutation from 'data/hooks/useMutation';
// import { Contact, ContactCreate } from '@transactional-message-tool/shared';
// import { ContactFormValues } from './types';
import { useParams } from 'react-router-dom';
import useCreateContactModal from 'data/hooks/useCreateEditContactModal';

const NoContactsMessage = () => {
    const { appId, groupId } = useParams() as { appId: string; groupId: string };
    const { openCreateEditContact } = useCreateContactModal({ appId, groupId, action: 'create' });
    return (
        <StyledNoMessageContainer
            icon={<Contacts />}
            title="Create your contacts to start messaging."
            description={
                <div>
                    To start messaging, you must first create or import your contacts. You can
                    integrate your existing contacts using the{' '}
                    <StyledLink
                        target="_blank"
                        href="https://nexxtway.gitbook.io/retalkable/api/api-references"
                        rel="noreferrer"
                    >
                        reTalkable API
                    </StyledLink>
                    .
                </div>
            }
            action={
                <Button variant="brand" borderRadius="semi-rounded" onClick={openCreateEditContact}>
                    <StyledPlusIcon />
                    Create New Contact
                </Button>
            }
        />
    );
};

export default NoContactsMessage;
