import { LogoLarge } from 'components/icons';
import styled from 'styled-components';
import { color } from './../../theme';

export const LayoutContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: 100vh;
    background-color: ${color('background.secondary')};
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
`;

export const LeftBar = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
    gap: 32px;
    width: 280px;
    height: 100vh;
    background: #f5f5fa;
    flex: none;
    order: 0;
    flex-grow: 0;
`;

export const LogoContainer = styled.div`
    height: 76px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${color('border.disabled')};
    width: 100%;
`;

export const LogoLargeIcon = styled(LogoLarge)`
    width: 75%;
    color: ${color('brand.main')};
`;
export const RightContent = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: calc(100% - 280px);
    height: 100vh;
    background: white;
`;

export const PageContent = styled.main`
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: scroll;
`;
