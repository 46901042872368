import { ButtonIcon } from 'react-rainbow-components';
import { ApiKey } from '@transactional-message-tool/shared';
import { Trash } from '../../../../../components/icons';
import { StyledColumnWrapper, TrashIconDelete } from '../styled';
import { useParams } from 'react-router-dom';
import useMutation from 'data/hooks/useMutation';
import { confirmModal } from '@rainbow-modules/app';
import { useQueryClient } from 'react-query';

interface Row extends ApiKey {
    new?: boolean;
}

interface Props {
    row?: Row;
}

const ActionColumn = ({ row }: Props) => {
    const { appId } = useParams();
    const queryClient = useQueryClient();
    const deleteApiKey = useMutation<string, ApiKey>({
        method: 'delete',
        successMessage: 'API Key removed successfully',
    });
    const apikeyId = row?.id;

    const onRemove = async () => {
        const result = await confirmModal({
            header: 'Delete API Key',
            question: "This API Key will be deleted immediately. You can't undo this action.",
            okButtonLabel: 'Delete',
            variant: 'destructive',
            icon: <TrashIconDelete />,
        });
        if (result) {
            deleteApiKey({ path: `/apps/${appId}/apikeys/${apikeyId}` });
            queryClient.setQueryData<ApiKey[]>(
                `/apps/${appId}/apikeys`,
                (oldData) => oldData?.filter((item) => item.id !== apikeyId) || [],
            );
        }
    };

    return (
        <StyledColumnWrapper new={row?.new}>
            <ButtonIcon icon={<Trash />} variant="base" onClick={onRemove} />
        </StyledColumnWrapper>
    );
};

export default ActionColumn;
