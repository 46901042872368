import { Contact } from '@transactional-message-tool/shared';
import { db } from '../../firebase';
import { collection, doc, writeBatch } from 'firebase/firestore';
import getPhoneNumberParts from 'helpers/getPhoneNumberParts';
import { formatPhoneNumber } from 'formatters';

const batchAdd = (appId?: string, groupId?: string, contacts?: Contact[]) => {
    if (!appId || !groupId || !contacts) {
        return;
    }
    const batch = writeBatch(db);
    const contactsRef = collection(db, `apps/${appId}/groups/${groupId}/contacts`);

    contacts.forEach((contact) => {
        const newDocRef = doc(contactsRef);
        const { phoneNumber, ...rest } = contact;
        const formattedPhoneNumber = formatPhoneNumber(phoneNumber);
        batch.set(newDocRef, {
            ...rest,
            phoneNumber: formattedPhoneNumber,
            groupId,
            phoneNumberParts: getPhoneNumberParts(phoneNumber),
            createdAt: new Date(),
            updatedAt: new Date(),
        });
    });
    return batch.commit();
};

export default batchAdd;
