import GroupList from './groupList';
import { ResizableColumns } from '@rainbow-modules/layout';
import { Container } from './styled';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import useApp from 'data/hooks/useApp';
import { useEffect } from 'react';

const Groups = () => {
    const { appId, groupId } = useParams();
    const [app] = useApp(appId);
    const navigate = useNavigate();

    useEffect(() => {
        if (app?.defaultGroupId && !groupId) {
            navigate(`/app/${appId}/groups/${app?.defaultGroupId}`);
        }
    }, [app, groupId, appId, navigate]);

    return (
        <Container>
            <ResizableColumns
                initialDividerPosition={250}
                leftColumn={<GroupList />}
                minLeftWidth={{ px: 140 }}
                rightColumn={<Outlet />}
                minRightWidth={{ percent: 25 }}
            />
        </Container>
    );
};

export default Groups;
