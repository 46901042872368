import styled from 'styled-components';
import { Google } from '@rainbow-modules/icons';
import { Button } from 'react-rainbow-components';
import { color } from 'theme';
import { mediaSize } from '../../../constants';
import RetalkableLogo from 'components/icons/retalkableLogo';
import mySVG from 'assets/background.svg';

export const Container = styled.div`
    background-image: url(${mySVG});
    background-size: cover;
    background-position: right top;
    display: flex;
    height: 100vh;
    width: 100%;
`;

export const LoginWrapper = styled.div`
    background: ${color('background.main')};
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 480px;
    height: 100%;
    box-shadow: 0 0 30px #3749574d;
    padding: 20px 32px 0 32px;

    @media ${mediaSize.mobile} {
        width: 100%;
    }
`;

export const LoginLogo = styled(RetalkableLogo)`
    @media ${mediaSize.tablet} {
        width: 100%;
        height: 50px;
        margin-top: 25px;
    }
`;

export const WelcomeMessage = styled.h1`
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 49px;
    margin-bottom: 40px;
    width: 100%;
    text-align: center;
`;

export const StyledGoogleIcon = styled(Google)`
    width: 24px;
    height: 24px;
    margin-right: 20px;
`;

export const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

export const StyledButton = styled(Button)`
    max-width: 290px;
    width: 290px;
    border: 1px solid ${color('text.main')};
    color: ${color('text.main')};
    display: flex;
    padding-top: 4px;
    padding-bottom: 4px;
`;

export const TermsAndConditions = styled.div`
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: ${color('text.label')};
    margin-top: 40px;
    width: 80%;

    @media ${mediaSize.tablet} {
        width: 100%;
    }
`;

export const Footer = styled.p`
    position: absolute;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 19px;
    text-align: center;
    color: ${color('text.disabled')};
    margin-top: 40px;
    bottom: 50px;
    left: 50px;
`;

export const TermsLink = styled.a`
    color: ${color('text.main')};
    text-decoration: underline;
    font-weight: 600;
`;
