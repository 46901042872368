import PropTypes from 'prop-types';
import { IconProps } from './types';

const Trash = ({ title, className, style }: IconProps) => (
    <svg width={24} height={24} viewBox="0 0 24 24" className={className} style={style}>
        <title>{title}</title>
        <path
            fill="currentColor"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.8877 6.5293C2.8877 6.11508 3.22348 5.7793 3.6377 5.7793H21.6377C22.0519 5.7793 22.3877 6.11508 22.3877 6.5293C22.3877 6.94351 22.0519 7.2793 21.6377 7.2793H3.6377C3.22348 7.2793 2.8877 6.94351 2.8877 6.5293Z"
            className="Vector (Stroke)"
        />
        <path
            fill="currentColor"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.6377 3.2793C10.3062 3.2793 9.98823 3.41099 9.75381 3.64541C9.51939 3.87983 9.3877 4.19778 9.3877 4.5293V6.5293C9.3877 6.94351 9.05191 7.2793 8.6377 7.2793C8.22348 7.2793 7.8877 6.94351 7.8877 6.5293V4.5293C7.8877 3.79995 8.17743 3.10048 8.69315 2.58475C9.20888 2.06903 9.90835 1.7793 10.6377 1.7793H14.6377C15.367 1.7793 16.0665 2.06903 16.5822 2.58475C17.098 3.10048 17.3877 3.79995 17.3877 4.5293V6.5293C17.3877 6.94351 17.0519 7.2793 16.6377 7.2793C16.2235 7.2793 15.8877 6.94351 15.8877 6.5293V4.5293C15.8877 4.19778 15.756 3.87983 15.5216 3.64541C15.2872 3.41099 14.9692 3.2793 14.6377 3.2793H10.6377ZM5.6377 5.7793C6.05191 5.7793 6.3877 6.11508 6.3877 6.5293V20.5293C6.3877 20.8608 6.51939 21.1788 6.75381 21.4132C6.98823 21.6476 7.30617 21.7793 7.6377 21.7793H17.6377C17.9692 21.7793 18.2872 21.6476 18.5216 21.4132C18.756 21.1788 18.8877 20.8608 18.8877 20.5293V6.5293C18.8877 6.11508 19.2235 5.7793 19.6377 5.7793C20.0519 5.7793 20.3877 6.11508 20.3877 6.5293V20.5293C20.3877 21.2586 20.098 21.9581 19.5822 22.4738C19.0665 22.9896 18.367 23.2793 17.6377 23.2793H7.6377C6.90835 23.2793 6.20888 22.9896 5.69315 22.4738C5.17743 21.9581 4.8877 21.2586 4.8877 20.5293V6.5293C4.8877 6.11508 5.22348 5.7793 5.6377 5.7793Z"
            className="Vector (Stroke)_2"
        />
        <path
            fill="currentColor"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.6377 10.7793C11.0519 10.7793 11.3877 11.1151 11.3877 11.5293V17.5293C11.3877 17.9435 11.0519 18.2793 10.6377 18.2793C10.2235 18.2793 9.8877 17.9435 9.8877 17.5293V11.5293C9.8877 11.1151 10.2235 10.7793 10.6377 10.7793Z"
            className="Vector (Stroke)_3"
        />
        <path
            fill="currentColor"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.6377 10.7793C15.0519 10.7793 15.3877 11.1151 15.3877 11.5293V17.5293C15.3877 17.9435 15.0519 18.2793 14.6377 18.2793C14.2235 18.2793 13.8877 17.9435 13.8877 17.5293V11.5293C13.8877 11.1151 14.2235 10.7793 14.6377 10.7793Z"
            className="Vector (Stroke)_4"
        />
    </svg>
);

Trash.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    title: PropTypes.string,
};

Trash.defaultProps = {
    className: undefined,
    style: undefined,
    title: 'Trash',
};

export default Trash;
