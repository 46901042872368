import { HeaderTitle } from './styled';
import { TwilioResponse } from 'pages/app/Apps/types';
import { Field, isRequired } from '@rainbow-modules/forms';
import { useFormState } from 'react-final-form';
import { ValidationErrors } from 'final-form';
import { useState } from 'react';
import SelectStateAndAreaCode from './selectStateAndAreaCode';
import AvailablePhoneNumbersList from './availablePhoneNumbers';
import BackendClient from 'data/services/axios';
import { RenderIf } from 'react-rainbow-components';
import IsLoadingStateAndAreaCode from './loadingcard';

const gethasErrorStateAndAreaCode = (errors: ValidationErrors) => {
    return errors?.states || errors?.areaCode ? true : false;
};

const gethasErrorStateAndAreaCodeWhenTouched = (errors: ValidationErrors, touched: boolean) => {
    if (touched) {
        return errors?.states || errors?.areaCode ? true : false;
    }
    return false;
};

const getAvailableNumbers = async (areaCode: string, state: string) => {
    const response = await BackendClient.get(`/numbers/`, {
        params: {
            areaCode,
            state,
        },
    });
    return response;
};

const gethasErrorAvailableNumbers = (
    errors: ValidationErrors,
    availableNumbers: TwilioResponse[],
    submitFailed: boolean,
) => {
    if (availableNumbers.length === 0 && errors) {
        return Object.entries(errors).length === 1 && errors?.phoneNumber && submitFailed;
    }

    return errors?.phoneNumber && submitFailed && !gethasErrorStateAndAreaCode(errors);
};

const getErrorMessage = (errors: ValidationErrors) => {
    if (errors?.states && errors?.areaCode) {
        return 'You must select a state and area code to see the available phone numbers you can select for your group';
    } else if (errors?.states) {
        return 'You must select a state to see the available phone numbers you can select for your group';
    } else if (errors?.areaCode) {
        return 'You must enter an area code to see the available phone numbers you can select for your group';
    }
    return '';
};

const SelectPhoneNumberForm = () => {
    const [availableNumbers, setAvailableNumbers] = useState<TwilioResponse[]>([]);
    const [selectedNumber, setSelectedNumber] = useState<TwilioResponse>({} as TwilioResponse);

    const formState = useFormState();
    const { values, errors, submitFailed } = formState;
    const { states, areaCode } = values;

    const hasError = gethasErrorStateAndAreaCode(errors);
    const hasErrorWithTouched = gethasErrorStateAndAreaCodeWhenTouched(
        errors,
        formState.touched?.areaCode as boolean,
    );
    const hasErrorStateAndAreaCode = (hasError && submitFailed) || hasErrorWithTouched;
    const hasErrorPickPhoneNumber = gethasErrorAvailableNumbers(
        errors,
        availableNumbers,
        submitFailed,
    );

    const [isLoading, setIsLoading] = useState(false);

    const onSearch = async () => {
        if (areaCode === '' || Object.entries(states).length === 0) {
            return;
        }
        setIsLoading(true);
        const response = await getAvailableNumbers(areaCode, states.value as string);
        setIsLoading(false);
        setAvailableNumbers(response.data);
    };

    const onChangeSelectedNumber = (number: TwilioResponse) => {
        const selectedNumber = availableNumbers.find(
            (availablenumber) => availablenumber.friendlyName === number.friendlyName,
        );
        setSelectedNumber(selectedNumber as TwilioResponse);
    };

    return (
        <>
            <HeaderTitle>Select a phone number for your group</HeaderTitle>
            <SelectStateAndAreaCode
                onSearchAvailableNumbers={onSearch}
                areaCode={areaCode}
                selectedState={states}
                errorMessage={getErrorMessage(errors)}
                showError={hasErrorStateAndAreaCode}
                buttonDisabled={hasError}
            />
            <RenderIf isTrue={isLoading}>
                <IsLoadingStateAndAreaCode />
            </RenderIf>
            <RenderIf isTrue={!isLoading}>
                <AvailablePhoneNumbersList
                    availableNumbers={availableNumbers}
                    onChange={(number: TwilioResponse) => onChangeSelectedNumber(number)}
                    value={selectedNumber}
                    showError={hasErrorPickPhoneNumber}
                />
            </RenderIf>
            <Field
                name="phoneNumber"
                initialValue={selectedNumber.phoneNumber}
                required
                validate={isRequired()}
            >
                {({ input }) => <input type="hidden" {...input} />}
            </Field>
        </>
    );
};

export default SelectPhoneNumberForm;
