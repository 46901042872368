import styled from 'styled-components';
import { color } from '../../theme';
import { LoadingShape } from 'react-rainbow-components';

export const StyledLoadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 16px;
    gap: 15px;
`;

export const StyledLoadingShapeLarge = styled(LoadingShape)`
    max-width: 300px;
    height: 20px;
`;

export const StyledLoadingShapeMediumLarge = styled(LoadingShape)`
    max-width: 200px;
    height: 16px;
`;

export const StyledLoadingShapeMedium = styled(LoadingShape)`
    max-width: 150px;
    height: 16px;
`;

export const StyledLoadingShapeSmall = styled(LoadingShape)`
    max-width: 100px;
    height: 12px;
`;

export const HeaderIsLoadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const IsLoadingCardContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 8px 14px;
    border: 1px solid ${color('border.disabled')};
    border-radius: 10px;
    gap: 16px;
    margin-top: 25px;
`;

export const IsLoadingItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    min-width: 33%;
`;
