import { useState, useEffect } from 'react';
import { auth } from '../../firebase';

const useIdToken = () => {
    const [token, setToken] = useState<string | undefined>(undefined);
    useEffect(() => {
        (async () => {
            const token = await auth.currentUser?.getIdToken();
            setToken(token);
        })();
    }, []);
    return token;
};

export default useIdToken;
