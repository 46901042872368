import { useOpenModal } from '@rainbow-modules/hooks';
import { App, AppCreate } from '@transactional-message-tool/shared';
import useMutation from 'data/hooks/useMutation';
import { APP_MODAL_EDIT } from '../../constants';
import ModalTitle from 'components/ModalTitle';

const useUpdateApps = () => {
    const [openModal, closeModal] = useOpenModal(APP_MODAL_EDIT);
    const updateApp = useMutation<AppCreate, App>({
        method: 'patch',
        onSuccess: closeModal,
        successMessage: 'Application updated successfully',
    });

    const onEdit = (app: App) => {
        const { id, name, authUrl } = app;
        openModal({
            title: ModalTitle({ title: 'Edit Application' }),
            initialValues: {
                name,
                authUrl,
            },
            submitButtonLabel: 'Update',
            onSubmit: (values: AppCreate) => updateApp({ path: `/apps/${id}`, body: values }),
        });
    };

    return onEdit;
};

export default useUpdateApps;
