import { ButtonGroup, ButtonIcon } from 'react-rainbow-components';
import { Edit, Trash } from '@rainbow-modules/icons';
import { useNavigate } from 'react-router-dom';
import { App } from '@transactional-message-tool/shared';
import useMutation from 'data/hooks/useMutation';
import { confirmModal } from '@rainbow-modules/app';
import { TrashIconDelete } from '../Apps/styled';
import useUpdateApps from 'data/hooks/useUpdateApps';

interface ActionsProps {
    app?: App;
}

const Actions = (props: ActionsProps) => {
    const { app } = props;
    const navigate = useNavigate();
    const editApp = useUpdateApps();

    const removeApp = useMutation({
        method: 'delete',
        successMessage: 'Application removed successfully',
    });

    const onRemove = async () => {
        const { id } = app as App;
        const result = await confirmModal({
            icon: <TrashIconDelete />,
            variant: 'destructive',
            header: 'Delete Application',
            question: "This application will be deleted immediately. You can't undo this action.",
            okButtonLabel: 'Delete',
        });
        if (result) {
            removeApp({ path: `/apps/${id}` });
            navigate('/');
        }
    };
    return (
        <div>
            <ButtonGroup borderRadius="semi-rounded">
                <ButtonIcon
                    icon={<Edit />}
                    variant="outline-brand"
                    borderRadius="semi-rounded"
                    onClick={() => editApp(app as App)}
                />
                <ButtonIcon
                    icon={<Trash />}
                    variant="outline-brand"
                    borderRadius="semi-rounded"
                    onClick={onRemove}
                />
            </ButtonGroup>
        </div>
    );
};

export default Actions;
