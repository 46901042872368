/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useCollection } from '@rainbow-modules/firebase-hooks';
import { App } from '@transactional-message-tool/shared';
import { auth } from '../../firebase';
import { where, query } from 'firebase/firestore';

const useApps = () => {
    const uid = auth.currentUser?.uid;
    return useCollection<App>({
        path: '/apps',
        flat: true,
        // @ts-ignore
        query: (ref) => query(ref, where('owner', '==', uid), where('removed', '==', false)),
    });
};

export default useApps;
