import styled from 'styled-components';
import { color } from '../../../../theme';
import { CopyToClipboardButton } from '@rainbow-modules/record';
import GroupCountColumn from 'pages/app/Apps/columns/groupCount';
import { RecordDetails } from '@rainbow-modules/record';

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 32px 8px 0 8px;
`;

export const Header = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
`;

export const HeaderTitle = styled.h1`
    font-size: 22px;
    color: ${color('text.main')};
    font-family: 'Lato', Arial, Helvetica, sans-serif;
`;

export const SectionTitle = styled.div`
    font-size: 16px;
    color: ${color('text.main')};
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
`;

export const StyledCopyToClipboardButton = styled(CopyToClipboardButton)`
    padding: 8px;
    color: ${color('brand.main')};
    height: 32px;
    width: 32px;
`;

export const IdContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const StyledGroupCountColumn = styled(GroupCountColumn)`
    padding: 0;
`;

export const StyledRecordDetails = styled(RecordDetails)`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;
