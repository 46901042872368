import styled from 'styled-components';
import { Avatar, AvatarMenu } from 'react-rainbow-components';
import { color } from '../../../theme';
import { mediaSize, TOP_BAR_HEIGHT } from '../../../constants';
import { Roles } from '@rainbow-modules/icons';

export const Container = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: ${TOP_BAR_HEIGHT}px;
    min-height: ${TOP_BAR_HEIGHT}px;
    margin: 0 32px;
    background-color: ${color('background.main')};
    z-index: 1;
    box-sizing: border-box;
    border-bottom: solid 1px ${color('border.disabled')};

    @media ${mediaSize.tablet} {
        justify-content: space-between;
        align-items: center;
        padding: 0 12px;
    }

    @media ${mediaSize.mobile} {
        padding: 0 8px;
        align-items: center;
    }
`;

export const LeftContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media ${mediaSize.mobile} {
        align-items: end;
        flex-direction: column-reverse;
    }
`;

export const RightContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media ${mediaSize.mobile} {
        align-items: end;
        flex-direction: column-reverse;
    }
`;

export const AvatarContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const PageContent = styled.main`
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: scroll;
`;

export const StyledAvatarMenu = styled(AvatarMenu)`
    flex-grow: 0;
    flex-shrink: 0;
    margin-top: 4px;
`;

export const UserInformationContainer = styled.div`
    display: flex;
    padding: 4px 32px 12px 12px;
`;

export const UserInformation = styled.div`
    margin-left: 12px;
`;

export const UserName = styled.h5`
    font-size: 14px;
    color: ${color('text.main')};
`;

export const UserEmail = styled.p`
    font-size: 12px;
    color: ${color('text.header')};
`;

export const MenuIcon = styled(Roles)`
    height: 16px;
`;

export const LayoutContainer = styled.div`
    display: flex;
    width: 100vw;
    height: 100vh;
    background-color: ${color('background.secondary')};
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
`;

export const StyledAvatar = styled(Avatar)`
    width: 32px;
    height: 32px;
    border-radius: 25%;
`;

export const StyledApplicationPicker = styled.div`
    background-color: ${color('brand.main')};
    width: 20px;
    height: 20px;
`;
