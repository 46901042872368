import Topbar from 'layout/MainLayout/Topbar';
import AppsTable from './appsTable';
import AppsHeader from './appsHeader';
import useApps from 'data/hooks/useApps';
import { Container } from './styled';
import NoAppsMessage from './noAppsMessage';
import { RenderIf } from 'react-rainbow-components';

const Apps = () => {
    const [apps, isLoading] = useApps();

    return (
        <Container>
            <Topbar />
            <RenderIf isTrue={apps.length === 0 && !isLoading}>
                <NoAppsMessage />
            </RenderIf>
            <RenderIf isTrue={apps.length > 0 || isLoading}>
                <AppsHeader />
                <AppsTable apps={apps} isLoading={isLoading} />
            </RenderIf>
        </Container>
    );
};

export default Apps;
