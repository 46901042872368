import { Timestamp } from 'firebase/firestore';
import { CSSProperties } from 'react';
import { Container } from './styled';
import formatter from 'data/services/date/formatter';

interface DateTimeColumnProps {
    value?: Timestamp;
    style?: CSSProperties;
    className?: string;
}

const DateTimeColumn = (props: DateTimeColumnProps) => {
    const { value, className, style } = props;
    if (!value) return null;
    return (
        <Container style={style} className={className}>
            {formatter.format(value?.toDate())}
        </Container>
    );
};

export default DateTimeColumn;
