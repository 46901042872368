import { useOpenModal } from '@rainbow-modules/hooks';
import useMutation from 'data/hooks/useMutation';
import { Contact, ContactCreate, PhoneNumberParts } from '@transactional-message-tool/shared';
import { ContactFormValues } from 'pages/app/Groups/Contacts/types';
import { ReactElement } from 'react';
import ModalTitle from 'components/ModalTitle';

interface initialValuesProps {
    firstName: string | ReactElement;
    lastName: string | ReactElement;
    phone: PhoneNumberParts;
}

interface UseCreateContactModalProps {
    appId: string;
    groupId: string;
    action: 'create' | 'edit';
    initialValues?: initialValuesProps;
    id?: string;
}

const useCreateEditContactModal = (props: UseCreateContactModalProps) => {
    const { appId, groupId, action, initialValues, id } = props;
    const [openContactModal, closeContactModal] = useOpenModal('add-edit-contact');

    const method = action === 'create' ? 'post' : 'patch';
    const successMessage = action === 'create' ? 'created' : 'updated';
    const mutation = useMutation<ContactCreate, Contact>({
        method,
        onSuccess: closeContactModal,
        successMessage: `Contact ${successMessage} successfully`,
    });

    const title = action === 'create' ? 'Create new contact' : 'Edit contact';
    const submitButtonLabel = action === 'create' ? 'Create contact' : 'Edit contact';
    const path =
        action === 'create'
            ? `/apps/${appId}/groups/${groupId}/contacts`
            : `/apps/${appId}/groups/${groupId}/contacts/${id}`;

    const openCreateEditContact = () =>
        openContactModal({
            title: ModalTitle({ title }),
            submitButtonLabel,
            initialValues,
            onSubmit: (values: ContactFormValues) => {
                const {
                    firstName,
                    lastName,
                    phone: { phone, countryCode },
                } = values;
                const data = {
                    firstName,
                    lastName,
                    phoneNumber: `${countryCode}${phone}`,
                };
                mutation({
                    path,
                    body: data,
                });
            },
        });

    return { openCreateEditContact };
};

export default useCreateEditContactModal;
