import PropTypes from 'prop-types';
import { OptionContainer, OptionName, StyledChevronRight } from './styled';

interface Props {
    style?: React.CSSProperties;
    className?: string;
    name?: string;
    onClick?: () => void;
    isSelected?: boolean;
}

const Option = (props: Props) => {
    const { className, style, name, onClick, isSelected } = props;

    return (
        <OptionContainer
            className={className}
            style={style}
            onClick={onClick}
            isSelected={isSelected}
        >
            <OptionName>{name}</OptionName>
            <StyledChevronRight />
        </OptionContainer>
    );
};

Option.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    name: PropTypes.string,
    selected: PropTypes.bool,
};

Option.defaultProps = {
    className: '',
    style: {},
    name: undefined,
    selected: false,
};

export default Option;
