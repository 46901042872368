import PropTypes from 'prop-types';
import {
    GroupItemContainer,
    GroupItemName,
    GroupItemDescription,
    StyledChevronRight,
    GroupLeftContent,
} from './styled';

interface Props {
    style?: React.CSSProperties;
    className?: string;
    groupName?: string;
    description?: string;
    onClick?: () => void;
    isSelected?: boolean;
}

const GroupItem = (props: Props) => {
    const { className, style, groupName, description, onClick, isSelected } = props;

    return (
        <GroupItemContainer
            isSelected={isSelected}
            className={className}
            style={style}
            onClick={onClick}
        >
            <GroupLeftContent>
                <GroupItemName isSelected={isSelected}>{groupName}</GroupItemName>
                <GroupItemDescription isSelected={isSelected}>{description}</GroupItemDescription>
            </GroupLeftContent>
            <StyledChevronRight />
        </GroupItemContainer>
    );
};

GroupItem.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    groupName: PropTypes.string,
    description: PropTypes.string,
};

GroupItem.defaultProps = {
    className: '',
    style: {},
    groupName: undefined,
    description: undefined,
};

export default GroupItem;
