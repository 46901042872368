import PropTypes from 'prop-types';
import { IconProps } from './types';

const Contacts = ({ className, style }: IconProps) => {
    return (
        <svg
            className={className}
            style={style}
            width={100}
            height={100}
            viewBox="0 0 102 84"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M77.8531 45.9179H77.9365C86.8115 45.9179 94.0615 38.6679 94.0615 29.8346C94.0615 20.9596 86.8115 13.7096 77.9365 13.7096C76.1865 13.7096 74.5198 14.0012 72.9365 14.5012C75.8115 18.7512 77.4781 23.8762 77.4781 29.4179C77.5198 35.0429 75.7698 40.4596 72.4781 44.9596C74.1865 45.5846 75.9781 45.9179 77.8531 45.9179Z"
                fill="#EBE7F1"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.0199 45.9179H24.1449C26.0199 45.9179 27.8115 45.5846 29.5199 44.9596C26.3532 40.6262 24.4782 35.2512 24.4782 29.4179C24.4782 23.8762 26.1449 18.7512 29.0199 14.5012C27.4365 14.0012 25.7699 13.7096 24.0199 13.7096C15.1449 13.7096 7.93652 20.9596 7.93652 29.8346C7.93652 38.6679 15.1449 45.9179 24.0199 45.9179Z"
                fill="#EBE7F1"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M50.9806 49.6932H51.1181C56.514 49.6766 61.5807 47.5557 65.3806 43.7266C69.1848 39.8974 71.2682 34.8182 71.2473 29.4307C71.2473 18.2516 62.1598 9.15991 50.9806 9.15991C39.8056 9.15991 30.7181 18.2516 30.7181 29.4307C30.7181 40.6016 39.8056 49.6932 50.9806 49.6932Z"
                fill="#EBE7F1"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M77.9786 51.917C73.2286 51.917 68.7702 52.8753 65.0202 54.5003C78.1452 58.292 87.4369 67.3753 87.6869 78.0003C94.5202 77.1253 101.895 74.542 101.895 67.5003C101.895 59.042 90.9369 51.917 77.9786 51.917Z"
                fill="#EBE7F1"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M36.9368 54.4587C33.1868 52.8753 28.7285 51.917 24.0202 51.917C11.0618 51.917 0.103516 59.042 0.103516 67.5003C0.103516 74.542 7.43685 77.0837 14.2702 78.0003C14.4785 67.3753 23.7702 58.292 36.9368 54.4587Z"
                fill="#EBE7F1"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M50.9769 58.7379C34.4727 58.7379 20.5269 67.7213 20.5269 78.3546C20.5269 92.1046 43.4436 92.1046 50.9769 92.1046C64.0519 92.1046 81.4352 90.6879 81.4352 78.4421C81.4352 67.7588 67.4852 58.7379 50.9769 58.7379Z"
                fill="#EBE7F1"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M50.9754 58.7368C34.4712 58.7368 20.5254 67.7201 20.5254 78.3535C20.5254 92.1035 43.4421 92.1035 50.9754 92.1035C64.0504 92.1035 81.4337 90.6868 81.4337 78.441C81.4337 67.7577 67.4837 58.7368 50.9754 58.7368Z"
                fill="#6750A4"
            />
            <path
                d="M51.1188 49.6932H50.9813C39.8063 49.6932 30.7188 40.6016 30.7188 29.4307C30.7188 18.2516 39.8063 9.15991 50.9813 9.15991C62.1604 9.15991 71.2479 18.2516 71.2479 29.4307C71.2688 34.8182 69.1854 39.8974 65.3813 43.7266C61.5813 47.5557 56.5146 49.6766 51.1188 49.6932Z"
                fill="#6750A4"
            />
        </svg>
    );
};

Contacts.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
};

Contacts.defaultProps = {
    className: undefined,
    style: undefined,
};

export default Contacts;
