import styled, { css } from 'styled-components';
import { ChevronRight } from '@rainbow-modules/icons';
import { color } from '../../../theme';
import { Button, Input } from 'react-rainbow-components';

export const Container = styled.div`
    padding: 32px 32px 0 32px;
    height: 100%;
`;

export const Header = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
`;

export const HeaderTitle = styled.h1`
    font-size: 18px;
    color: ${color('text.main')};
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
`;

interface OptionContainerProps {
    isSelected?: boolean;
}

export const OptionContainer = styled.li<OptionContainerProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 8px 14px;
    border: 1px solid ${color('border.disabled')};
    border-radius: 10px;
    margin-bottom: 8px;

    ${(props) =>
        props.isSelected &&
        css`
            background-color: ${color('brand.light')};
            border: 1px solid ${color('brand.light')};
        `}

    &:hover {
        background-color: ${color('brand.light')};
        border: 1px solid ${color('brand.light')};
        cursor: pointer;
    }
`;

export const OptionName = styled.h3`
    font-size: 15px;
    color: ${color('text.main')};
    width: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export const StyledChevronRight = styled(ChevronRight)`
    color: ${color('brand.main')};
    flex-shrink: 0;
`;

export const StyledButton = styled(Button)`
    width: 100%;
`;

export const PreviewPageContainer = styled.div`
    background-color: white;
    width: 100%;
    height: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    margin-bottom: 56px;
    padding: 40px 64px;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 24px;
    box-sizing: border-box;
    height: 100%;
`;

export const LargeRow = styled.div`
    width: 100%;
    height: 20px;
    background-color: ${color('background.highlight')};
    margin-bottom: 12px;
    border-radius: 2px;
`;

export const SmallRow = styled(LargeRow)`
    width: 50%;
`;

export const Row = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 16px;
`;

export const ModalTitle = styled.h1`
    text-align: left;
    font-size: 20px;
    color: ${color('text.main')};
    padding: 12px 24px;
`;

export const ModalDescription = styled.p`
    font-size: 14px;
    color: ${color('text.label')};
    padding: 0 12px;
`;

export const FieldContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 16px;
`;

export const StyledInput = styled(Input)`
    width: 46rem;
`;

export const StyledSaveButton = styled(Button)`
    height: 40px;
    margin-top: 22px;
`;
