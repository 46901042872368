import styled from 'styled-components';
import { Button } from 'react-rainbow-components';
import { color } from '../../theme';
import { mediaSize, TOP_BAR_HEIGHT } from '../../constants';
import { ArrowLeft } from '@rainbow-modules/icons';
import RetalkableLogo from 'components/icons/retalkableLogo';

export const LayoutContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${color('background.secondary')};
    box-sizing: border-box;
`;

export const TopBar = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: ${TOP_BAR_HEIGHT}px;
    min-height: ${TOP_BAR_HEIGHT}px;
    background-color: ${color('background.main')};
    z-index: 1;
    box-sizing: border-box;
    border-bottom: solid 1px ${color('background.highlight')};
    padding: 0 56px;

    @media (${mediaSize.tablet}) {
        justify-content: space-between;
        align-items: center;
        padding: 0 12px;
    }

    @media ${mediaSize.mobile} {
        padding: 0 8px;
        align-items: center;
    }
`;

export const LoginLogo = styled(RetalkableLogo)`
    @media (min-width: 768px) {
        top: 50px;
        left: 50px;
        width: 100px;
        height: 50px;
    }
`;

export const RightContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 20px;
    width: 100%;

    @media ${mediaSize.mobile} {
        align-items: end;
        flex-direction: row;
    }
`;

export const StyledButton = styled(Button)`
    margin-right: 12px;
    width: 100px;
`;

export const PageContent = styled.main`
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: scroll;
`;

export const Content = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 80px;
    margin-top: 56px;
`;

export const PrincipalHeader = styled.h1`
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 44px;
    margin-top: 20px;
    margin-bottom: 20px;
`;

export const Text = styled.p`
    font-family: 'Inter', sans-serif;
    white-space: pre-wrap;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
`;

export const Subheading = styled.p`
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
`;

export const GoBackLink = styled.a`
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-decoration: underline;
    color: ${color('text.main')};
    cursor: pointer;
    margin-bottom: 20px;
`;

export const StyledArrowLeftIcon = styled(ArrowLeft)`
    margin-right: 8px;
    color: ${color('text.main')};
`;
