import { Header, HeaderTitle, Content, Row } from '../styled';
import { useParams } from 'react-router-dom';
import useApp from 'data/hooks/useApp';
import useMutation from 'data/hooks/useMutation';
import { App, AppCreate } from '@transactional-message-tool/shared';
import { UniversalForm } from '@rainbow-modules/forms';
import AuthUrlFormFields from './authUrlFormFields';

const Identity = () => {
    const { appId } = useParams();
    const [app] = useApp(appId);
    const initialAuthUrl = app?.authUrl || '';

    const updateApp = useMutation<AppCreate, App>({
        method: 'patch',
        successMessage: 'Application updated successfully',
    });

    const onSubmit = (values: Record<string, unknown>) => {
        const { authUrl } = values;
        updateApp({ path: `/apps/${appId}`, body: { authUrl } });
    };

    return (
        <Content>
            <Header>
                <HeaderTitle>Identity</HeaderTitle>
            </Header>
            <Row>
                <UniversalForm
                    id="authUrlForm"
                    onSubmit={onSubmit}
                    initialValues={{ authUrl: initialAuthUrl }}
                >
                    <AuthUrlFormFields initialAuthUrl={initialAuthUrl} />
                </UniversalForm>
            </Row>
        </Content>
    );
};

export default Identity;
