import { Button } from 'react-rainbow-components';
import {
    StyledMessageContainer,
    StyledNoAppsMessage,
    StyledNoAppsMessageDescription,
    StyledNoMessageContainer,
    StyledPlusIcon,
} from './styled';
import useCreateApps from 'data/hooks/useCreateApps';

const NoAppsMessage = () => {
    const openCreateApp = useCreateApps();

    return (
        <StyledNoMessageContainer>
            <img src="noMessageImage.png" alt="No apps" />
            <StyledMessageContainer>
                <StyledNoAppsMessage>Start sending messages to your customers</StyledNoAppsMessage>
                <StyledNoAppsMessageDescription>
                    To start messaging your customers, you must first create an appplication. Once
                    you&apos;ve built your app, you can start using <b>reTalkable</b>.
                </StyledNoAppsMessageDescription>
            </StyledMessageContainer>
            <Button variant="brand" borderRadius="semi-rounded" onClick={openCreateApp}>
                <StyledPlusIcon />
                Create New Application
            </Button>
        </StyledNoMessageContainer>
    );
};

export default NoAppsMessage;
